<template>
  <div>
    <!-- filter -->
    <b-card
      no-body
      class="mb-1"
    >
      <b-card-header class="py-1">
        <h4 class="card-title font-weight-bolder">
          {{ $t('filters') }}
        </h4>
        <div class="d-flex align-items-center justify-content-end">
          <!-- ANCHOR Button Search -->
          <b-button
            variant="info"
            class="mr-1"
            @click="fetchBanks()"
          >
            <span class="text-nowrap">
              <feather-icon
                class="cursor-pointer"
                icon="SearchIcon"
                size="16"
              />
              {{ $t('search') }}
            </span>
          </b-button>

          <!-- ANCHOR Button Clear Search Filters -->
          <b-button
            variant="danger"
            @click="fetchBanks(true)"
          >
            <span class="text-nowrap">
              <feather-icon
                class="cursor-pointer"
                icon="XOctagonIcon"
                size="16"
              />
              {{ $t('clear') }}
            </span>
          </b-button>
        </div>
      </b-card-header>

      <b-card-body class="py-0">
        <b-container fluid>
          <b-row>
            <!-- ANCHOR searchText -->
            <b-col md="6">
              <b-form-group label-for="searchText">
                <template #label>
                  {{ $t('bank.form.name') }}
                </template>
                <b-form-input
                  id="searchText"
                  v-model="filter.searchText"
                  :name="$t('bank.form.name')"
                  :placeholder="$t('bank.form.name')"
                  :debounce="600"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>

    <!-- table -->
    <b-card no-body>
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <b-table
          ref="refDataTable"
          bordered
          no-border-collapse
          sticky-header
          responsive
          show-empty
          primary-key="id"
          style="max-height: 70vh"
          class="position-relative"
          table-class="table-resell-ticket"
          :items="banks"
          :fields="tableColumns"
          :empty-text="$t('noRecordFund')"
          :sort-by.sync="filter.sortBy"
          :sort-desc.sync="filter.isSortDirDesc"
        >
          <!-- ANCHOR Header -->
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]="data"
          >
            <div
              :key="column.label"
              class="text-dark text-nowrap text-center"
            >
              <template>
                {{ $t(`bank.columns.${data.label}`) }}
              </template>
            </div>
          </template>

          <!-- ANCHOR Value -->
          <template
            v-for="column in tableColumns"
            #[`cell(${column.key})`]="{ item }"
          >
            <div
              :key="column.key"
              class="text-nowrap"
              :class="{
                'text-right': typeof item[column.key] === 'number',
              }"
            >
              {{ item[column.key] }}
            </div>
          </template>

          <!-- ANCHOR stt. -->
          <template #cell(stt)="data">
            <span class="text-right">
              {{ data.index + 1 }}</span>
          </template>

          <!-- ANCHOR createdAt. -->
          <template #cell(createdAt)="{ item }">
            <span class="text-right">
              {{ convertISODateTime(item.createdAt, 0).dateTime }}
            </span>
          </template>
          <!-- ANCHOR sepayFeeConfig. -->
          <template #cell(sepayFeeConfig)="{ item }">
            <span class="text-right">
              {{ formatVnCurrency(item.sepayFeeConfig) }}
            </span>
          </template>

          <!-- ANCHOR isAuto. -->
          <template #cell(isAuto)="{ item }">
            <b-form-checkbox
              v-model="item.isAuto"
              :switch="true"
              @click.native.prevent="onSwichStatus(item)"
            />
          </template>

          <!-- ANCHOR name. -->
          <template #cell(name)="{ item }">
            <span
              v-if="item.name"
              class="text-nowrap"
            >
              <BImg
                :src="item.logo"
                width="40"
              />
              {{ item.name }} ({{ item.code }})
            </span>
          </template>

          <!-- ANCHOR actions. -->
          <template #cell(actions)="{ item }">
            <div>
              <!-- ANCHOR editBtn. -->
              <b-button
                id="editBtn"
                v-b-tooltip.hover.v-dark.window="$t('bank.btn.edit')"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-primary"
                class="py-50 px-75 rounded"
                size="md"
                @click="onUpdate(item)"
              >
                <IAmIcon
                  icon="editOutline"
                  size="18"
                />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-overlay>
      <!-- paging -->
      <IAmPaging
        :page="filter.page"
        :size="filter.size"
        :total="total"
        :refDataTable="refDataTable"
        @set-page="(val)=>filter.page = val"
        @set-size="(val)=>filter.size = val"
      />
    </b-card>

    <!-- modal -->
    <b-card>
      <validation-observer
        #default="{ invalid, passes }"
        ref="refFormObserver"
      >
        <b-modal
          id="bank-form-modal"
          :title="$t('bank.form.title')"
          size="md"
          :centered="true"
          :scrollable="false"
          :hide-header="false"
          :hide-footer="false"
          :no-close-on-backdrop="false"
          :no-close-on-esc="false"
          :ok-only="false"
          :ok-disabled="false"
          ok-title="OK"
          ok-variant="primary"
          cancel-title="Cancel"
          cancel-variant="secondary"
          @show="onLoad"
        >
          <IAmOverlay :loading="loadingForm">
            <b-col>
              <BImg
                :src="bank.logo"
                width="80"
              />
              {{ bank.name }}
            </b-col>
            <!-- ANCHOR gateway -->
            <b-col class="mt-2">
              <validation-provider
                #default="validationContext"
                :name="$t('bank.form.gateway')"
                vid="gateway"
                rules="required"
              >
                <b-form-group label-for="gateway">
                  <template #label>
                    {{ $t('bank.form.gateway') }}
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="gateway"
                    v-model="bankForm.gateway"
                    :name="$t('bank.form.gateway')"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :placeholder="$t('bank.form.gateway')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR sepayFeeConfig -->
            <b-col>
              <validation-provider
                #default="validationContext"
                :name="$t('bank.form.sepayFeeConfig')"
                vid="sepayFeeConfig"
                rules="required"
              >
                <b-form-group label-for="sepayFeeConfig">
                  <template #label>
                    {{ $t('bank.form.sepayFeeConfig') }}
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="sepayFeeConfig"
                    v-model="bankForm.sepayFeeConfig"
                    v-remove-non-numeric-chars.allNumber
                    :name="$t('bank.form.sepayFeeConfig')"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :placeholder="$t('bank.form.sepayFeeConfig')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR isAuto -->
            <b-col>
              <validation-provider
                #default="validationContext"
                :name="$t('bank.form.isAuto')"
                vid="isAuto"
                rules="required"
              >
                <b-form-group label-for="isAuto">
                  <template #label>
                    {{ $t('bank.form.isAuto') }}
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-checkbox
                    id="isAuto"
                    v-model="bankForm.isAuto"
                    switch
                    :name="$t('bank.form.isAuto')"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :placeholder="$t('bank.form.isAuto')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </IAmOverlay>
          <template #modal-footer>
            <div class="w-100">
              <b-button
                variant="primary"
                class="float-right"
                :disabled="invalid"
                @click="updateBank(bank.id,() => $bvModal.hide('bank-form-modal'))"
              >
                {{ $t('submit') }}
              </b-button>
            </div>
          </template>
        </b-modal>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BContainer,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BImg,
  BOverlay,
  BRow,
  BTable,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import formValidation from '@/@core/comp-functions/forms/form-validation'
import {
  sizePerPageLgOptions,
} from '@/constants/selectOptions'
import IAmPaging from '@/components/IAmPaging.vue'

import {
  convertISODateTime,
  formatVnCurrency,
} from '@core/utils/filter'

import useBankHandle from '@sepay/useBankHandle'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BContainer,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BImg,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
    BFormInvalidFeedback,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    IAmPaging,
  },
  setup() {
    //
    const {
    // Const
      loading,
      loadingForm,
      bank,
      banks,
      total,
      filter,
      bankForm,

      // Functions
      fetchBanks,
      fetchBank,
      updateBank,
      getLogsById,
    } = useBankHandle()

    onMounted(() => {
      fetchBanks()
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()
    const bankId = ref(null)
    const refDataTable = ref(null)
    const tableColumns = ref([
      { key: 'stt', label: 'stt' },
      { key: 'name', label: 'name', sortable: true },
      { key: 'shortName', label: 'shortName', sortable: true },
      { key: 'isAuto', label: 'isAuto', sortable: true },
      { key: 'sepayFeeConfig', label: 'sepayFeeConfig', sortable: true },
      { key: 'createdAt', label: 'createdAt' },
      { key: 'actions', label: 'actions' },
    ])

    function onLoad() {
      if (bankId.value) fetchBank(bankId.value)
    }

    function onUpdate(item) {
      bankId.value = item.id
      this.$bvModal.show('bank-form-modal')
    }
    function onSwichStatus(item) {
      fetchBank(item.id)
      // ANCHOR modal confirm
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.changeStatus') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            bankForm.value.isAuto = !bankForm.value.isAuto
            updateBank(item.id)
          }
        })
    }
    return {
      refDataTable,
      refFormObserver,
      tableColumns,
      loading,
      loadingForm,
      bank,
      banks,
      total,
      filter,
      bankForm,

      // Functions
      fetchBanks,
      fetchBank,
      updateBank,
      getLogsById,
      onUpdate,
      onLoad,
      getValidationState,
      onSwichStatus,
      // import
      sizePerPageLgOptions,
      convertISODateTime,
      formatVnCurrency,
    }
  },
}
</script>
